import React from "react"
import Button from 'react-bootstrap/Button'
import { Link } from "gatsby"

const ChapterNavigation = ({storySlug, nextChapter, isFinished}) => {
    let interfaceButton = ""
    //console.log(nextChapter)
    if (nextChapter){
        interfaceButton =  <Link to={`/stories/${storySlug}/${nextChapter.slug}`}><Button variant="primary" size="lg">Lee la siguiente parte</Button></Link>
    } else if (isFinished){
        interfaceButton =  <Link to={`/stories/${storySlug}`}><Button variant="primary" size="lg">Fin</Button></Link>
    } else{
        interfaceButton =  <Link to={`/stories/${storySlug}`}><Button variant="primary" size="lg">Continuará...</Button></Link>
    }

    return (

        <div className="d-grid gap-2">
            {interfaceButton}
        </div>

    )
}

export default ChapterNavigation
